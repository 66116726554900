@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Martel";
  src: url("fonts/martel.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.bg-image {
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(26, 16, 61, 0.5),
      rgba(19, 11, 48, 1)
    ),
    url("https://static.tibia.com/images/global/header/background-artwork.webp");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-base-300;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*@apply bg-primary;*/
}
